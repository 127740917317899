.add-expense-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

.add-expense-form .ant-form-item {
  margin-bottom: 15px;
}

.form-buttons {
  display: flex;;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .add-expense-form .ant-form-item {
    margin-bottom: 10px;
  }

  .form-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .form-buttons .ant-btn {
    margin-bottom: 10px;
  }
}
