.interactive-card,
.square-card {
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.interactive-card:hover,
.square-card:hover {
  transform: scale(1.05);
  background-color: #f0f8ff;
  /* Light blue on hover */
}

.icon {
  font-size: 3rem;
  color: #007bff;
  /* Primary blue color */
}

.icon:hover {
  color: #0056b3;
  /* Darker blue on hover */
}

.expenses-home-container {
  height: 92vh;
  /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.row {
  width: 100%;
  /* Ensure the row spans the container width */
}

.square-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  /* Ensure the card is square */
  max-width: 250px;
  /* Adjust as needed for size */
  margin: auto;
  /* Center cards within their columns */
}

@media (max-width: 768px) {
  .interactive-card h5 {
    font-size: 1rem;
  }

  .icon {
    font-size: 2.5rem;
  }

  .square-card {
    max-width: 200px;
    /* Smaller size for mobile */
  }
}
